import { sortBy } from 'lodash-es'
import { useMemo } from 'react'
import { useGeographiesQuery } from '~/api'
import { aOrganization, useAtomValue } from '~/atoms'
import { Button, SelectMultiple } from '~/components/ui'

export default function MapSubmarketsFilter({
  submarkets,
  updateFilter,
}: {
  submarkets: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const organization = useAtomValue(aOrganization)
  const find = useMemo(() => {
    return {}
  }, [])

  const { geographies, isLoading } = useGeographiesQuery({
    key: ['map-geographies', 'submarket'],
    find: { type: 'submarket', ...find },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    enabled: organization!.slug.includes('carnm'),
  })

  const submarketOptions = useMemo(() => {
    const submarketOptions: { label: string; value: string }[] = []
    geographies.forEach((geo) => {
      if (
        submarketOptions.findIndex((option) => option.value === geo.name) === -1
      ) {
        submarketOptions.push({
          label: geo.label || geo.name,
          value: geo.name,
        })
      }
    })
    return sortBy(submarketOptions, 'label')
  }, [geographies])

  const selectedSubmarkets = useMemo(
    () => submarketOptions.filter(({ value }) => submarkets.includes(value)),
    [submarkets, submarketOptions]
  )

  if (geographies.length === 0 && !isLoading) {
    return null
  }

  return (
    <div className="space-y-2">
      <p className="text-sm">
        Submarkets
        {selectedSubmarkets.length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('submarkets', [])}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        isPopover={false}
        placeholder={
          isLoading && geographies.length === 0
            ? 'Loading Submarkets...'
            : 'Search Submarkets...'
        }
        options={submarketOptions}
        selected={selectedSubmarkets}
        setSelected={(selected) => {
          updateFilter(
            'submarkets',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
