import { LucideExternalLink } from 'lucide-react'
import { useMemo } from 'react'
import { aOrganization, useAtomValue } from '~/atoms'
import { Button, SelectMultiple } from '~/components/ui'

export default function MapBrokersFilter({
  brokers,
  filterBrokers,
  updateFilter,
}: {
  brokers: { firstName: string; lastName: string; tid: string; slug: string }[]
  filterBrokers?: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const organization = useAtomValue(aOrganization)
  const brokersData = useMemo(() => {
    return brokers.map((broker) => ({
      label: `${broker.firstName} ${broker.lastName}`,
      value: broker.tid,
      slug: broker.slug,
    }))
  }, [brokers])

  const selectedBrokers = useMemo(() => {
    if (!filterBrokers) {
      return []
    }
    return brokersData.filter(({ value }) => filterBrokers.includes(value))
  }, [filterBrokers, brokersData])

  return (
    <div className="space-y-2">
      <p className="text-sm">
        {organization!.options.appraisersOnly ? 'Appraisers' : 'Brokers'}
        {(filterBrokers || []).length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('brokers', undefined)}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        isPopover={false}
        placeholder={
          organization!.options.appraisersOnly
            ? brokers.length === 0
              ? 'Loading Appraisers...'
              : 'Search Appraisers...'
            : brokers.length === 0
              ? 'Loading Brokers...'
              : 'Search Brokers...'
        }
        endAdornment={(broker: any) => (
          <a href={`/brokers/${broker.slug}`} target="_blank" rel="noreferrer">
            <LucideExternalLink className="-m-1 rounded-md p-1.5 text-muted-foreground/40 hover:bg-background" />
          </a>
        )}
        hideEmptySearchResults={brokers.length > 50}
        options={brokersData}
        selected={selectedBrokers}
        setSelected={(selected) => {
          updateFilter(
            'brokers',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
