import { useMemo } from 'react'
import { Button, SelectMultiple } from '~/components/ui'

export default function PostalCodeFilter({
  postalCodeValues,
  filterPostalCode,
  updateFilter,
}: {
  postalCodeValues: string[]
  filterPostalCode: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const postalCodeData = useMemo(
    () =>
      postalCodeValues.map((zone) => {
        const abbr = zone.split(' ')[0]
        return { label: abbr, value: abbr }
      }),
    [postalCodeValues]
  )

  return (
    <div className="space-y-2">
      <p className="text-sm">
        Zip Code
        {filterPostalCode.length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('postalCodes', undefined)}>
            (reset)
          </Button>
        )}
      </p>
      <SelectMultiple
        isPopover={false}
        placeholder="Search zip codes to filter..."
        options={postalCodeData}
        selected={filterPostalCode.map((v) => ({ label: v, value: v }))}
        setSelected={(selected) => {
          updateFilter(
            'postalCodes',
            selected.map(({ value }) => value)
          )
        }}
      />
    </div>
  )
}
