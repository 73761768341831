import { Link } from '@remix-run/react'
import { useEffect, useMemo, useState } from 'react'
import { Layer, LayerProps, Popup, Source, useMap } from 'react-map-gl'
import {
  aDrawMode,
  aDrawnFeature,
  aParcelDataResidentialVisible,
  aParcelDataVisible,
  useAtomValue,
} from '~/atoms'
import { Button } from '~/components/ui'
import { MapView, useCurrentMapView } from '~/hooks'
import { ParcelData } from '~/models'
import { getParcelLabelValues } from '~/utils'

export default function MapParcelsLayer() {
  const parcelDataVisible = useAtomValue(aParcelDataVisible)
  const parcelDataResidentialVisible = useAtomValue(
    aParcelDataResidentialVisible
  )
  const currentMapView = useCurrentMapView()
  const mapRef = useMap()
  const [selectedParcel, setSelectedParcel] = useState<ParcelData | null>(null)

  const drawMode = useAtomValue(aDrawMode)
  const drawFeature = useAtomValue(aDrawnFeature)
  const [shouldInitLayer, setShouldInitLayer] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setShouldInitLayer(true)
    }, 500)
  }, [])

  useEffect(() => {
    if (mapRef.current && !drawMode) {
      const map = mapRef.current?.getMap()
      let hoveredPolygonId: string | null = null
      let isListingClicked = false
      map.on('click', 'property-single-clusters', (e) => {
        if (e.features && e.features.length > 0) {
          isListingClicked = true
          setSelectedParcel(null)
        } else {
          isListingClicked = false
        }
      })
      map.on('click', 'parcels-fills', (e) => {
        if (!isListingClicked && (e.features || []).length > 0) {
          setSelectedParcel(e.features![0].properties as ParcelData)
        }
        isListingClicked = false
      })
      map.on('mousemove', 'parcels-fills', (e) => {
        isListingClicked = false

        if ((e.features || []).length > 0) {
          if (hoveredPolygonId !== null) {
            mapRef.current!.getMap().getCanvasContainer().style.cursor = 'grab'
            map.setFeatureState(
              {
                source: 'parcel-data',
                sourceLayer: 'parcels',
                id: hoveredPolygonId,
              },
              { hover: false }
            )
          }
          hoveredPolygonId = e.features![0].id as string
          mapRef.current!.getMap().getCanvasContainer().style.cursor = 'pointer'
          map.setFeatureState(
            {
              source: 'parcel-data',
              sourceLayer: 'parcels',
              id: hoveredPolygonId,
            },
            { hover: true }
          )
        }
      })
      map.on('mouseleave', 'parcels-fills', () => {
        isListingClicked = false
        if (hoveredPolygonId !== null) {
          mapRef.current!.getMap().getCanvasContainer().style.cursor = 'grab'
          map.setFeatureState(
            {
              source: 'parcel-data',
              sourceLayer: 'parcels',
              id: hoveredPolygonId,
            },
            { hover: false }
          )
        }
        hoveredPolygonId = null
      })
    }
  }, [mapRef.current])

  const layerStyle: LayerProps = {
    id: 'parcels',
    'source-layer': 'parcels',
    filter: ['!=', ['get', 'land_use_code'], 'R'],
    type: 'line',
    beforeId: 'property-clusters',
    interactive: true,
    paint: {
      'line-color': '#000',
      'line-opacity': ['match', ['get', 'LAND_USE_C'], 'R', 0, 0.15],
    },
    layout: {},
  }

  const layerStyleFill = useMemo(() => {
    const style: LayerProps = {
      id: 'parcels-fills',
      type: 'fill',
      'source-layer': 'parcels',
      beforeId: 'property-clusters',
      layout: {},
      paint: {
        'fill-color': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          '#7c3aed',
          '#000000',
        ],
        'fill-opacity': [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.25,
          0.1,
        ],
      },
    }
    if (parcelDataResidentialVisible === false) {
      style.filter = ['!=', ['get', 'land_use_code'], 'R']
    }
    return style
  }, [parcelDataResidentialVisible])

  if (
    !parcelDataVisible ||
    currentMapView !== MapView.Map ||
    !!drawMode ||
    !!drawFeature ||
    !shouldInitLayer
  ) {
    return null
  }

  return (
    <>
      <Source
        id="parcel-data"
        type="vector"
        url="https://serve.resimplifi.com/tiles/parcels:nm.json"
        // url="http://serve.resimplifi.loc:1211/tiles/parcels:nm.json"
        promoteId="id"
        minzoom={13}>
        <Layer {...layerStyle} />
        <Layer {...layerStyleFill} />
      </Source>
      {selectedParcel && (
        <Popup
          className="show-close select-text"
          maxWidth="400px"
          key={selectedParcel.id}
          latitude={selectedParcel.latitude}
          longitude={selectedParcel.longitude}
          closeButton={true}
          closeOnMove={true}
          closeOnClick={false}
          onClose={() => setSelectedParcel(null)}
          style={{ zIndex: 48 }}>
          <p className="mb-2 text-sm font-medium leading-tight">
            {[
              selectedParcel.address,
              selectedParcel.city,
              selectedParcel.state_abbr,
              selectedParcel.zip_code,
              selectedParcel.county ? `${selectedParcel.county} County` : null,
            ]
              .filter((v) => v)
              .join(', ')}
          </p>
          <dl className="-mr-4 max-h-96 overflow-auto text-sm">
            {getParcelLabelValues(selectedParcel).map(
              ({ key, label, value }) => {
                return (
                  <div key={key} className="mb-1 grid grid-cols-3 gap-2">
                    <dt className="col-span-1 font-medium leading-tight">
                      {label}:
                    </dt>
                    <dd className="col-span-2">{value}</dd>
                  </div>
                )
              }
            )}
          </dl>
          <div className="text-right">
            <Button size="sm" variant="link">
              <Link
                to={'/admin/listings/create'}
                state={{
                  latitude: selectedParcel.latitude,
                  longitude: selectedParcel.longitude,
                  address: selectedParcel.address,
                  stateAbbr: selectedParcel.state_abbr,
                  postalCode: selectedParcel.zip_code,
                  county: selectedParcel.county,
                }}>
                Create Listing
              </Link>
            </Button>
          </div>
        </Popup>
      )}
    </>
  )
}
