import { useMemo } from 'react'
import { Button, SelectMultiple } from '~/components/ui'

export default function ZoningFilter({
  zoningValues,
  filterZoning,
  updateFilter,
}: {
  zoningValues: string[]
  filterZoning: string[]
  updateFilter: (key: string, value: any) => void
}) {
  const zonesData = useMemo(
    () =>
      zoningValues.map((zone) => {
        return { label: zone, value: zone }
      }),
    [zoningValues]
  )
  const selectedZones = useMemo(
    () => zonesData.filter(({ value }) => filterZoning.includes(value)),
    [filterZoning, zonesData]
  )

  return (
    <div className="space-y-2">
      <p className="text-sm">
        Zoning
        {selectedZones.length > 0 && (
          <Button
            variant="link"
            size="none"
            className="ml-2"
            onClick={() => updateFilter('zoning', undefined)}>
            (reset)
          </Button>
        )}
      </p>

      <SelectMultiple
        isPopover={false}
        placeholder="Search zoning..."
        options={zonesData}
        selected={selectedZones}
        // hideEmptySearchResults={true}
        clearInputOnSelect={false}
        setSelected={(selected) => {
          updateFilter(
            'zoning',
            selected.map(({ value }) => value)
          )
        }}
      />
      <p className="text-sm italic">
        Zoning filter uses text search. For instance, selecting "C1" will also
        find listings tagged with zoning "A1/C1."
      </p>
    </div>
  )
}
