import { Link } from '@remix-run/react'
import {
  LucideBarChart4,
  LucideCircleDotDashed,
  LucideFileText,
  LucideScan,
  LucideSquare,
  LucideSquareCheck,
  LucideWaypoints,
} from 'lucide-react'
import { useState } from 'react'
import { useAdminReportsQuery } from '~/api'
import {
  aCheckedListings,
  aDrawnFeature,
  aMapFilters,
  aMapViewState,
  aUser,
  useAtom,
  useAtomValue,
} from '~/atoms'
import { ControlTooltip, CreatePdfDialog } from '~/components'
import { Badge, Button, Command, Popover } from '~/components/ui'
import ReportAddToExisting from './report/AddToExisting'

export default function MapReportActions() {
  const user = useAtomValue(aUser)
  const [checkedListings, setCheckedListings] = useAtom(aCheckedListings)
  const [drawnFeature, setDrawnFeature] = useAtom(aDrawnFeature)
  const mapViewState = useAtomValue(aMapViewState)
  const mapFilters = useAtomValue(aMapFilters)
  const [isOpen, setIsOpen] = useState(false)

  const { reports } = useAdminReportsQuery({
    key: ['user-reports', user?._id || ''],
    find: {
      'user._id': user?._id,
      deleted: { $ne: true },
      includeIds: { $ne: null },
    },
    refetchInterval: 1000 * 15,
    enabled: !!user,
  })

  if (!user) {
    return null
  }

  return (
    <Popover open={isOpen} onOpenChange={(value) => setIsOpen(value)}>
      <ControlTooltip text="Report Actions" side="bottom">
        <Popover.Trigger asChild>
          <Button
            variant="map"
            size="map"
            className="relative w-auto px-3"
            onClick={() => setIsOpen(!isOpen)}>
            <LucideBarChart4 className="mr-2 h-5 w-5" />
            Reports
            {checkedListings.length > 0 && (
              <Badge className="pointer-events-none absolute -right-1 -top-1 inline-flex h-4 items-center justify-center rounded-full px-1 py-0 text-xs">
                {checkedListings.length}
              </Badge>
            )}
          </Button>
        </Popover.Trigger>
      </ControlTooltip>

      <Popover.Content className={'w-[220px] p-0'} align="start">
        <Command>
          <Command.List className="flex !max-h-none flex-col">
            <Command.Group heading="Create" className="text-foreground">
              {checkedListings.length > 0 && (
                <Command.Item className="p-0">
                  <Button
                    asChild
                    variant="ghost"
                    size="sm"
                    className="w-full justify-start !bg-transparent pl-2">
                    <Link
                      viewTransition
                      to="/admin/reports/create"
                      state={{ listingIds: checkedListings }}>
                      <LucideSquareCheck className="mr-2 h-4 w-4 text-muted-foreground" />
                      From Selection
                    </Link>
                  </Button>
                </Command.Item>
              )}

              {mapViewState && !drawnFeature && (
                <Command.Item className="p-0">
                  <Button
                    asChild
                    variant="ghost"
                    size="sm"
                    className="w-full justify-start !bg-transparent pl-2">
                    <Link
                      viewTransition
                      to="/admin/reports/create"
                      state={{
                        bounds: {
                          ne: Object.values(
                            mapViewState!.bounds.getNorthEast()
                          ),
                          sw: Object.values(
                            mapViewState!.bounds.getSouthWest()
                          ),
                        },
                        filters: mapFilters,
                      }}>
                      <LucideScan className="mr-2 h-4 w-4 text-muted-foreground" />
                      From Map Area
                    </Link>
                  </Button>
                </Command.Item>
              )}
              {drawnFeature && (
                <Command.Item className="p-0">
                  <Button
                    asChild
                    variant="ghost"
                    size="sm"
                    className="w-full justify-start !bg-transparent pl-2">
                    <Link
                      viewTransition
                      to="/admin/reports/create"
                      state={{
                        feature: drawnFeature
                          ? {
                              geometry: drawnFeature.geometry,
                              type: drawnFeature.type,
                              id: drawnFeature.id,
                              properties: drawnFeature.properties,
                            }
                          : null,
                        filters: mapFilters,
                      }}>
                      {drawnFeature.properties?.isCircle ? (
                        <LucideCircleDotDashed className="mr-2 h-4 w-4 text-muted-foreground" />
                      ) : (
                        <LucideWaypoints className="mr-2 h-4 w-4 text-muted-foreground" />
                      )}
                      {drawnFeature.properties?.isCircle
                        ? 'From Circle'
                        : 'From Polygon'}
                    </Link>
                  </Button>
                </Command.Item>
              )}
            </Command.Group>

            {reports.length > 0 && checkedListings.length > 0 && (
              <Command.Group heading="Update" className="pt-0 text-foreground">
                <Command.Item className="h-11 p-0">
                  <ReportAddToExisting
                    reports={reports}
                    checkedListings={checkedListings}
                  />
                </Command.Item>
              </Command.Group>
            )}

            {checkedListings.length > 0 && (
              <>
                <Command.Group className="pt-0">
                  <Command.Separator className="my-1" />
                  <Command.Item className="p-0">
                    <CreatePdfDialog listingIds={checkedListings}>
                      <Button
                        variant="ghost"
                        size="sm"
                        className="w-full justify-start !bg-transparent pl-2">
                        <LucideFileText className="mr-2 h-4 w-4 text-muted-foreground" />
                        Download PDF
                      </Button>
                    </CreatePdfDialog>
                  </Command.Item>
                  {/* </Command.Group>
                <Command.Group className="pt-0"> */}
                  <Command.Separator className="my-1" />
                  <Command.Item className="p-0">
                    <Button
                      variant="ghost"
                      size="sm"
                      className="w-full justify-start !bg-transparent pl-2"
                      onClick={() => {
                        // setDrawnFeature(null)
                        setCheckedListings([])
                        setIsOpen(false)
                      }}>
                      <LucideSquare className="mr-2 h-4 w-4 text-muted-foreground" />
                      Clear Selected
                    </Button>
                  </Command.Item>
                </Command.Group>
              </>
            )}
          </Command.List>
        </Command>
      </Popover.Content>
    </Popover>
  )
}
