import type { MapFiltersType } from '~/atoms'
import MapMinMaxFilter from './MinMax'

export default function MapIndustrialFilters({
  filters,
  updateFilter,
}: {
  filters: MapFiltersType
  updateFilter: (key: string, value: any) => void
}) {
  return (
    <>
      <MapMinMaxFilter
        name="clearHeight"
        label="Clear Height"
        filterValues={filters.clearHeight || []}
        updateFilter={updateFilter}
      />
      <MapMinMaxFilter
        name="eaveHeight"
        label="Eave Height"
        filterValues={filters.eaveHeight || []}
        updateFilter={updateFilter}
      />
      <MapMinMaxFilter
        name="warehouseSf"
        label="Warehouse SF"
        filterValues={filters.warehouseSf || []}
        updateFilter={updateFilter}
      />
      {/* < name="warehouseHvac" label="Warehouse HVAC" filterValues={filters.warehouseHvac || []} updateFilter={updateFilter} /> */}
      <MapMinMaxFilter
        name="officeSf"
        label="Office SF"
        filterValues={filters.officeSf || []}
        updateFilter={updateFilter}
      />
      <MapMinMaxFilter
        name="officeCeilingHeight"
        label="Office Ceiling Height"
        filterValues={filters.officeCeilingHeight || []}
        updateFilter={updateFilter}
      />
      <MapMinMaxFilter
        name="mezzanineSf"
        label="Mezzanine SF"
        filterValues={filters.mezzanineSf || []}
        updateFilter={updateFilter}
      />
      {/* < name="mezzanineHvac" label="Mezzanine HVAC" filterValues={filters.mezzanineHvac || []} updateFilter={updateFilter} /> */}
      <MapMinMaxFilter
        name="dockNumber"
        label="Dock Number"
        filterValues={filters.dockNumber || []}
        updateFilter={updateFilter}
      />
      <MapMinMaxFilter
        name="driveInNumber"
        label="Drive In Number"
        filterValues={filters.driveInNumber || []}
        updateFilter={updateFilter}
      />
      <MapMinMaxFilter
        name="trailerParkingSpaces"
        label="Trailer Parking Spaces"
        filterValues={filters.trailerParkingSpaces || []}
        updateFilter={updateFilter}
      />
      <MapMinMaxFilter
        name="freightDockNumber"
        label="Freight Dock Number"
        filterValues={filters.freightDockNumber || []}
        updateFilter={updateFilter}
      />
      <MapMinMaxFilter
        name="overheadCranes"
        label="Overhead Cranes"
        filterValues={filters.overheadCranes || []}
        updateFilter={updateFilter}
      />
      <MapMinMaxFilter
        name="railDoors"
        label="Rail Doors"
        filterValues={filters.railDoors || []}
        updateFilter={updateFilter}
      />
      {/* < name="yard" label="Yard" filterValues={filters.yard || []} updateFilter={updateFilter} /> */}
      {/* < name="yardFenced" label="Yard Fenced" filterValues={filters.yardFenced || []} updateFilter={updateFilter} /> */}
      {/* < name="yardPaved" label="Yard Paved" filterValues={filters.yardPaved || []} updateFilter={updateFilter} /> */}
    </>
  )
}
