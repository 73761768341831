// https://github.com/mxkaske/mxkaske.dev/blob/main/components/craft/fancy-multi-select.tsx

import { Command as CommandPrimitive } from 'cmdk'
import { LucideX } from 'lucide-react'
import React, { useCallback, useMemo, useRef, useState } from 'react'
import { cn } from '~/utils'
import { Badge } from './badge'
import { Command } from './command'

export type SelectMultipleOption = {
  value: string
  label: string
  displayLabel?: React.ReactNode
  disableClose?: boolean
  [key: string]: any
}

export function SelectMultiple({
  placeholder,
  options,
  selected = [],
  setSelected,
  hideEmptySearchResults = false,
  clearInputOnSelect = true,
  startAdornment,
  endAdornment,
  noResultsText = 'No Results Found',
  onKeyUp,
  contentClassName,
  onOpenChange,
  isPopover = true,
}: {
  placeholder: string
  options: SelectMultipleOption[]
  selected?: SelectMultipleOption[]
  setSelected: (selected: SelectMultipleOption[]) => void
  hideEmptySearchResults?: boolean
  clearInputOnSelect?: boolean
  startAdornment?: (option: SelectMultipleOption) => React.ReactNode
  endAdornment?: (option: SelectMultipleOption) => React.ReactNode
  noResultsText?: string
  onKeyUp?: React.KeyboardEventHandler<HTMLInputElement>
  contentClassName?: string
  onOpenChange?: (open: boolean) => void
  isPopover?: boolean
}) {
  const inputRef = useRef<HTMLInputElement>(null)
  const [open, setOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')

  const availableOptions = useMemo(
    () =>
      options.filter(
        (option) => !selected.map((s) => s.value).includes(option.value)
      ),
    [selected, options]
  )

  const handleUnselect = useCallback(
    (option: SelectMultipleOption) => {
      setSelected(selected.filter((s) => s.value !== option.value))
    },
    [selected]
  )

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      const input = inputRef.current
      if (input) {
        if (e.key === 'Delete' || e.key === 'Backspace') {
          if (input.value === '') {
            setSelected(
              selected.filter((value, index) => index !== selected.length - 1)
            )
          }
        }
        // This is not a default behavior of the <input /> field
        if (e.key === 'Escape') {
          input.blur()
        }
      }
    },
    [selected]
  )

  return (
    <Command
      onKeyDown={handleKeyDown}
      filter={(value) => {
        return value.toLowerCase().includes(inputValue.toLowerCase()) ? 1 : 0
      }}
      className="overflow-visible bg-transparent">
      <div className="group rounded-md border border-input px-3 py-2 text-sm ring-offset-background focus-within:ring-2 focus-within:ring-ring focus-within:ring-offset-2">
        <div className="flex flex-wrap gap-x-1 gap-y-2">
          {selected.map((option) => {
            return (
              <Badge key={option.value} variant="secondary">
                {option.label}
                {!option.disableClose && (
                  <button
                    className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                    onKeyDown={(e) => {
                      if (e.key === 'Enter') {
                        handleUnselect(option)
                      }
                    }}
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onClick={() => handleUnselect(option)}>
                    <LucideX className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                  </button>
                )}
              </Badge>
            )
          })}
          {/* Show input if options aren't available/loading to show loading text */}
          {(options.length === 0 || availableOptions.length > 0) && (
            <CommandPrimitive.Input
              ref={inputRef}
              value={inputValue}
              onValueChange={setInputValue}
              onBlur={() => {
                setOpen(false)
                onOpenChange?.(false)
              }}
              onFocus={() => {
                setOpen(true)
                onOpenChange?.(true)
              }}
              onKeyUp={onKeyUp}
              placeholder={placeholder}
              className="my-px flex-1 bg-transparent outline-none placeholder:text-muted-foreground"
            />
          )}
        </div>
      </div>

      {open &&
      availableOptions.length > 0 &&
      (!hideEmptySearchResults || inputValue.length > 1) ? (
        <div className="relative">
          <div
            className={cn(
              'z-10 w-full rounded-md border bg-popover text-popover-foreground shadow-md outline-none animate-in',
              isPopover ? 'absolute top-2' : 'mt-2'
            )}>
            <Command.Empty className="">{noResultsText}</Command.Empty>
            <Command.Group
              className={cn(
                'z-50 h-full max-h-64 overflow-auto',
                contentClassName
              )}>
              {availableOptions.map((option) => {
                return (
                  <Command.Item
                    key={option.value}
                    value={option.label}
                    onMouseDown={(e) => {
                      e.preventDefault()
                      e.stopPropagation()
                    }}
                    onSelect={(value) => {
                      if (clearInputOnSelect) {
                        setInputValue('')
                      }
                      setSelected([...selected, option])
                    }}
                    className={'cursor-pointer justify-between'}>
                    <span className="inline-flex gap-2">
                      {startAdornment && startAdornment(option)}
                      {option.displayLabel || <span>{option.label}</span>}
                    </span>
                    {endAdornment && endAdornment(option)}
                  </Command.Item>
                )
              })}
            </Command.Group>
          </div>
        </div>
      ) : null}
    </Command>
  )
}
