import { isEqual, omit } from 'lodash-es'
import { LucidePin, LucidePinOff, LucideSlidersHorizontal } from 'lucide-react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useMap } from 'react-map-gl'
import { useAdminCompaniesQuery, useAdminUsersQuery } from '~/api'
import {
  aDefaultMapFilters,
  aFiltersPinned,
  aMapFilters,
  aOrganization,
  aUser,
  useAtom,
  useAtomValue,
  type MapFiltersType,
} from '~/atoms'
import { ControlTooltip, PulseDot } from '~/components'
import { Accordion, Button, Sheet } from '~/components/ui'
import { useBreakpoint, useIsEmbed, useIsSafari } from '~/hooks'
import { UserRole } from '~/models'
import { cn } from '~/utils'
import {
  LocationFilters,
  MapBrokersFilter,
  MapCompaniesFilter,
  MapDateFilter,
  MapIndustrialFilters,
  MapListingStatusFilter,
  MapMinMaxFilter,
  MapPropertySubtypesFilter,
  MapPropertyTypesFilter,
  MapSearchFilter,
  MapSort,
  MapTypeFilter,
} from './filters'

const INDUSTRIAL_FILTERS = [
  'clearHeight',
  'eaveHeight',
  'warehouseSf',
  'warehouseHvac',
  'officeSf',
  'officeCeilingHeight',
  'mezzanineSf',
  'mezzanineHvac',
  'dockNumber',
  'driveInNumber',
  'trailerParkingSpaces',
  'freightDockNumber',
  'overheadCranes',
  'railDoors',
  'yard',
  'yardFenced',
  'yardPaved',
]

function getObjectDiff(
  obj1: { [key: string]: any },
  obj2: { [key: string]: any }
) {
  const diff = Object.keys(obj1).reduce((result, key) => {
    if (!obj2.hasOwnProperty(key)) {
      result.push(key)
    } else if (isEqual(obj1[key], obj2[key])) {
      const resultKeyIndex = result.indexOf(key)
      result.splice(resultKeyIndex, 1)
    }
    return result
  }, Object.keys(obj2))

  return diff
}

function runInterval(callback: () => void, interval: number, times: number) {
  let counter = 0
  const timer = setInterval(() => {
    if (counter >= times) {
      clearInterval(timer)
    } else {
      callback()
      counter += 1
    }
  }, interval)
}

function FilterAccordion({
  className,
  label,
  children,
  filters,
  keys,
}: {
  className?: string
  label: string
  children: React.ReactNode
  filters: MapFiltersType
  keys?: string[]
}) {
  const showDot = useMemo(() => {
    return !!keys && keys.some((key) => key in filters)
  }, [keys, filters])
  return (
    <Accordion.Item value={label.toLowerCase()} className={className}>
      <Accordion.Trigger className="my-2 w-full justify-between px-1 py-2">
        <span className="relative flex-1 text-left">{label}</span>
        {showDot && <PulseDot className="mr-2" />}
      </Accordion.Trigger>
      <Accordion.Content className="flex flex-col gap-4 px-1" asChild>
        {children}
        <div className="h-2" />
      </Accordion.Content>
    </Accordion.Item>
  )
}

export default function MapFilterSheet() {
  const organization = useAtomValue(aOrganization)
  const user = useAtomValue(aUser)
  const defaultMapFilters = useAtomValue(aDefaultMapFilters)
  const [mapFilters, setMapFilters] = useAtom(aMapFilters)
  const [filters, setFilters] = useState(mapFilters)
  const isEmbed = useIsEmbed()
  const isSafari = useIsSafari()
  const [accordionState, setAccordionState] = useState<string[]>(['listing'])

  const [isOpen, setIsOpen] = useState(false)
  const [isPinned, setIsPinned] = useAtom(aFiltersPinned)
  const mapRef = useMap()
  const { lgDown } = useBreakpoint()

  const toggleSheetPinned = () => {
    setIsPinned(!isPinned)
  }

  useEffect(() => {
    const mapContainer = document.getElementById('map-container')
    if (mapContainer) {
      if (isPinned) {
        mapContainer.style.paddingRight = '24rem'
      } else {
        mapContainer.style.paddingRight = '0'
      }
      runInterval(
        () => {
          mapRef.current!.triggerRepaint()
          mapRef.current!.resize()
        },
        50,
        5
      )
    }
  }, [isPinned])

  useEffect(() => {
    if (lgDown && isPinned) {
      setIsPinned(false)
    }
  }, [lgDown, isPinned])

  const { companies } = useAdminCompaniesQuery(['active-companies'], {
    status: 'active',
  })
  const { users: brokers } = useAdminUsersQuery(['active-brokers'], {
    status: 'active',
    role: {
      $in: [UserRole.broker, UserRole.managingBroker],
    },
  })

  useEffect(() => {
    if (!isOpen) {
      setFilters(mapFilters)
    }
  }, [isOpen, mapFilters, setFilters])

  const updateFilter = useCallback(
    (key: string, value: any) => {
      const newFilters: { [key: string]: any } = { ...filters }
      if (typeof value === 'undefined') {
        delete newFilters[key]
      } else {
        newFilters[key] = value
      }
      // Clearing subtypes elsehwere caused issues
      if (key === 'propertyTypes' && (!value || value.length === 0)) {
        delete newFilters['propertySubtypes']
      }
      setFilters(newFilters)
    },
    [filters]
  )

  const updateMapFilters = () => {
    if ((filters.types || []).includes('sale') === false) {
      filters.prices = []
      filters.caps = []
    }
    if ((filters.types || []).includes('lease') === false) {
      filters.rates = []
      filters.sf = []
    }
    if ((filters.propertyTypes || []).includes('Industrial') === false) {
      INDUSTRIAL_FILTERS.forEach((key) => {
        // @ts-ignore
        filters[key] = []
      })
    }
    setMapFilters(filters)
    if (!isPinned) {
      setIsOpen(false)
    }
  }

  const resetMapFilters = () => {
    const filters = {
      lat: mapFilters.lat,
      lng: mapFilters.lng,
      z: mapFilters.z,
    }
    setMapFilters(filters)
    setTimeout(() => setFilters({ ...filters, ...defaultMapFilters }), 200)
    setIsOpen(false)
  }

  const different = useMemo(() => {
    return (
      getObjectDiff(
        omit(mapFilters, ['lat', 'lng', 'z']),
        omit(defaultMapFilters, ['lat', 'lng', 'z'])
      ).length > 0
    )
  }, [mapFilters])

  return (
    <Sheet
      modal={false}
      open={isPinned || isOpen}
      onOpenChange={(open) => {
        if (!isPinned) {
          setIsOpen(open)
          if (!open) {
            setFilters(mapFilters)
          }
        }
      }}>
      {!isPinned && (
        <ControlTooltip text="Filter and sort listings" side="bottom">
          <Sheet.Trigger asChild>
            <Button variant="map" size="map" className="relative shadow-md">
              <LucideSlidersHorizontal className="h-5 w-5" />
              {different && !isEmbed && (
                <PulseDot className="absolute -right-0.5 -top-0.5" />
              )}
            </Button>
          </Sheet.Trigger>
        </ControlTooltip>
      )}
      <Sheet.Content
        className={cn('flex w-full flex-col', isPinned && '!duration-0')}
        side="right"
        onCloseClick={(e) => {
          setIsOpen(false)
          setIsPinned(false)
        }}>
        <Sheet.Header>
          <div className="flex items-center justify-between gap-2">
            <Sheet.Title className="mt-0">
              Filter Listings{' '}
              <Button
                variant="link"
                size="none"
                className="p-0"
                onClick={() => {
                  if (isSafari) {
                    resetMapFilters()
                  }
                  resetMapFilters()
                }}>
                (reset all)
              </Button>
            </Sheet.Title>
            <button
              className={cn(
                'absolute right-11 top-4 hidden rounded-sm opacity-70 ring-offset-background transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-secondary xl:block',
                isEmbed && '!hidden'
              )}
              onClick={() => toggleSheetPinned()}>
              {isPinned ? (
                <LucidePinOff className="h-4 w-4" />
              ) : (
                <LucidePin className="h-4 w-4" />
              )}
              <span className="sr-only">Pin Filters</span>
            </button>
          </div>
          {/* <Sheet.Description></Sheet.Description> */}
        </Sheet.Header>
        <div className="-mx-6 flex-1 overflow-auto px-5">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              if (isSafari) {
                updateMapFilters()
              }
              updateMapFilters()
            }}>
            <div className="space-y-4 px-1">
              <MapSort sort={filters.sort} updateFilter={updateFilter} />

              <MapSearchFilter
                search={filters.search}
                updateFilter={updateFilter}
              />
            </div>

            <Accordion
              type="multiple"
              value={accordionState}
              onValueChange={setAccordionState}>
              <FilterAccordion
                label="Listing"
                filters={filters}
                keys={[
                  'propertyTypes',
                  'propertySubtypes',
                  // 'statuses',
                  // 'types',
                ]}>
                <>
                  <MapPropertyTypesFilter
                    propertyTypes={filters.propertyTypes || []}
                    updateFilter={updateFilter}
                  />

                  <MapPropertySubtypesFilter
                    propertySubtypes={filters.propertySubtypes || []}
                    propertyTypes={filters.propertyTypes || []}
                    updateFilter={updateFilter}
                  />
                  {user && (
                    <MapListingStatusFilter
                      statuses={filters.statuses || []}
                      updateFilter={updateFilter}
                    />
                  )}
                  <MapTypeFilter
                    types={filters.types || []}
                    updateFilter={updateFilter}
                  />
                </>
              </FilterAccordion>

              {(filters.types || []).includes('sale') && (
                <FilterAccordion
                  label="Sale"
                  filters={filters}
                  keys={['prices', 'caps']}>
                  <MapMinMaxFilter
                    name="prices"
                    label="Sale Price"
                    start="$"
                    filterValues={filters.prices || []}
                    updateFilter={updateFilter}
                  />

                  <MapMinMaxFilter
                    name="caps"
                    label="Cap Rate"
                    end="%"
                    filterValues={filters.caps || []}
                    updateFilter={updateFilter}
                  />
                </FilterAccordion>
              )}

              {(filters.types || []).includes('lease') && (
                <FilterAccordion
                  label="Lease"
                  filters={filters}
                  keys={['rates', 'sf']}>
                  <>
                    <MapMinMaxFilter
                      name="rates"
                      label="Lease Rate"
                      start="$"
                      end="SF/yr"
                      filterValues={filters.rates || []}
                      updateFilter={updateFilter}
                    />
                    <MapMinMaxFilter
                      name="sf"
                      label="Available SF"
                      end="SF"
                      filterValues={filters.sf || []}
                      updateFilter={updateFilter}
                    />
                  </>
                </FilterAccordion>
              )}

              <FilterAccordion
                label="Building & Property"
                filters={filters}
                keys={['bsf', 'acres', 'ceilingHeight']}>
                <>
                  <MapMinMaxFilter
                    name="bsf"
                    label="Building SF"
                    end="SF"
                    filterValues={filters.bsf || []}
                    updateFilter={updateFilter}
                  />
                  <MapMinMaxFilter
                    name="acres"
                    label="Acres"
                    end="ac"
                    filterValues={filters.acres || []}
                    updateFilter={updateFilter}
                  />
                  <MapMinMaxFilter
                    name="ceilingHeight"
                    label="Ceiling Height"
                    filterValues={filters.ceilingHeight || []}
                    updateFilter={updateFilter}
                  />
                </>
              </FilterAccordion>

              {(filters.propertyTypes || []).includes('Industrial') && (
                <FilterAccordion
                  label="Industrial"
                  filters={filters}
                  keys={INDUSTRIAL_FILTERS}>
                  <MapIndustrialFilters
                    filters={filters}
                    updateFilter={updateFilter}
                  />
                </FilterAccordion>
              )}

              {organization!.options.singleCompany !== true && (
                <FilterAccordion
                  label="Location"
                  filters={filters}
                  keys={[
                    'zoning',
                    'submarkets',
                    'cities',
                    'counties',
                    'postalCodes',
                  ]}>
                  <>
                    <LocationFilters
                      filters={filters}
                      updateFilter={updateFilter}
                    />
                  </>
                </FilterAccordion>
              )}

              <FilterAccordion
                label="Dates"
                filters={filters}
                keys={['created', 'transacted']}>
                <>
                  {organization!.options.appraisersOnly !== true && (
                    <MapDateFilter
                      name="created"
                      label="Listed After"
                      filterValue={filters.created}
                      updateFilter={updateFilter}
                    />
                  )}

                  <MapDateFilter
                    className="!mb-0"
                    name="transacted"
                    label="Transacted After"
                    filterValue={filters.transacted}
                    updateFilter={updateFilter}
                  />
                  <p className="!-mt-2 text-sm italic">
                    Sold and Lease Statuses only
                  </p>
                </>
              </FilterAccordion>

              {(isEmbed === false ||
                organization!.options.singleCompany === true) && (
                <FilterAccordion
                  label="Members"
                  className="border-b-0"
                  filters={filters}
                  keys={['companies', 'brokers']}>
                  <>
                    {isEmbed === false &&
                      organization?.options.singleCompany !== true && (
                        <>
                          <MapCompaniesFilter
                            companies={companies}
                            filterCompanies={filters.companies}
                            updateFilter={updateFilter}
                          />
                          <MapBrokersFilter
                            brokers={brokers}
                            filterBrokers={filters.brokers}
                            updateFilter={updateFilter}
                          />
                        </>
                      )}
                    {organization!.options.singleCompany === true && (
                      <MapBrokersFilter
                        brokers={brokers}
                        filterBrokers={filters.brokers}
                        updateFilter={updateFilter}
                      />
                    )}
                  </>
                </FilterAccordion>
              )}
            </Accordion>
            <input className="hidden" type="submit" />
          </form>
        </div>
        <div className="flex gap-4 border-t pt-6">
          <Button
            onClick={() => {
              if (isSafari) {
                updateMapFilters()
              }
              updateMapFilters()
            }}>
            Update
          </Button>
          {!isPinned && (
            <Button
              variant="secondary"
              onClick={() => {
                setIsOpen(false)
              }}>
              Cancel
            </Button>
          )}
        </div>
      </Sheet.Content>
    </Sheet>
  )
}
